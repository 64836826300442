import React, { useEffect, useState } from 'react'
import cx from "classnames"
import { useModal } from "../modal2"

import styles from "./redirect-modal.module.css"
import { Button2 } from '../button2'

export const RedirectModal = ({ btnClassName }) => {
  const { show, RenderModal } = useModal()
  const [isModal, setIsModal] = useState(false)

  const checkIp = () => {
    fetch("http://ip-api.com/json/")
        .then(results => results.json())
        .then(data => {
          if (data.status === "success" && data.countryCode === "DE") {
            setIsModal(true);
            show();
          }
        })
  }

    useEffect(() => {
      checkIp()
  }, []);

  return (
    <>
      <RenderModal className={cx("modalForm", styles.modal)}>
        <div className={styles.modalWrap}>
          <h3>
            Sind Sie aus Deutschland?
          </h3>
          <p>
            Vielleicht wollten Sie auf die deutsche Website gelangen?
          </p>
          <a href='https://nutritionpro.pl'>
            <Button2
              color="secondary">
              Los geht's
            </Button2>
          </a>
        </div>
      </RenderModal>
    </>
  )
}
