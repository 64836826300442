import cx from 'classnames'
import React, { useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { AffiliateContext } from '../../contexts/AffiliateContext'
import { useSmoothScroll } from '../../hooks/useSmoothScroll'
import { Button2 } from '../button2'
import { ContactFormNew } from '../forms/contact-form-new'
import one from './1.svg'
import two from './2.svg'
import ava from './dietolog.jpg'
import ellipse from './ellipse.svg'
import marker from './marker.svg'
import styles from './nutritionist.module.css'

export const NutritionistSection = () => {
  const scroll = useSmoothScroll()
  const { t } = useTranslation()

  const openOrderForm = selector => {
    scroll.animateScroll(document.getElementById(selector))
  }

  const affiliateContext = useContext(AffiliateContext)
  const { isAffiliate } = affiliateContext.affiliate

  return (
    <section className={styles.nutritionistWrap}>
      <h3 className="fancyUnderlineText fancyUnderlineText--orange sectionTitleNew text-center">
        <Trans i18nKey="home.nutritionistTitle">
          Zawsze <span>pod kontrolą</span> specjalisty ds. żywienia
        </Trans>
      </h3>

      <div className={styles.nutritionist}>
        <div className={styles.nutritionistImg}>
          <div className={styles.avatar}>
            <img className={styles.avatarImg} src={ava} alt="avatar" />
            <div className={styles.avatarInfo}>
              <div className={styles.avatarInfoName}>
                <span>Emilia Gierada </span>
              </div>
              <div className={styles.avatarInfoPosition}>
                <img src={marker} alt="marker" />
                <span>Dietetyk Nutrition Pro</span>
                <img src={marker} alt="marker" />
              </div>
            </div>
          </div>
          <img
            className={styles.nutritionistImgEllipse}
            src={ellipse}
            alt="img"
          />
        </div>
        <div className={styles.nutritionistInfo}>
          <div className={styles.nutritionistInfoList}>
            <p className={styles.nutritionistInfoListItem}>
                Od 17 lat pracuję jako dietetyk i specjalista ds. żywności i
                żywienia. Jestem specjalistą w dziedzinie dietetycznego leczenia
                zaburzeń endokrynologicznych. Moje wykształcenie obejmuje
                kierunek Żywienie Człowieka i Dietetyka, który ukończyłam w
                Szkole Głównej Gospodarstwa Wiejskiego w Warszawie, a także
                liczne kursy i szkolenia, w tym w School of Insulin Resistance
                Therapy (SOIT). Moje doświadczenie zawodowe zdobyłam,
                współpracując z firmami cateringowymi specjalizującymi się w
                dietetyce zarówno w kraju, jak i za granicą, oraz prowadząc
                ponad 2000 konsultacji dietetycznych z indywidualnymi
                pacjentami. W tym czasie stworzyłam ponad 8000 jadłospisów
                dietetycznych dla różnych rodzajów diet. Jestem autorką wielu
                artykułów na temat dietetyki, książki o diecie w okresie
                menopauzy oraz książki o diecie z niskim indeksem glikemicznym
                dla osób z insulinoopornością.
            </p>
          </div>
          <div className={styles.nutritionistForm}>
            <div className={cx(styles.discountInfo, styles.formWrap)}>
              <ContactFormNew
                form_name={'konzultace-dietolog'}
                themeLight={true}
                horizontal={true}
                btnText="Zamów konsultację"
              />
            </div>
          </div>
          <div className={styles.nutritionistPrompt}>
            <span className={styles.nutritionistPromptOrder}>
              <span className={styles.textBold}>
                {t('home.nutritionistPromptOrderText1')}
              </span>{' '}
              {t('home.nutritionistPromptOrderText2')}
              <br></br>
              <span className={styles.textBold}>
                {t('home.nutritionistPromptOrderText3')}
              </span>{' '}
              {t('home.nutritionistPromptOrderText4')}
            </span>
            <div className={styles.nutritionistPromptImg}>
              <img className={styles.imgOne} src={one} alt="img" />
              <img src={two} alt="img" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
