import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { AnimatedWrapper } from '../animated-wrapper'
import { Container } from '../container'
import styles from './how-it-work.module.css'
import icon6 from './icons/Icons1.svg'
import icon4 from './icons/Icons2.svg'
import icon2 from './icons/Icons3.svg'
import icon1 from './icons/Icons4.svg'
import icon5 from './icons/Icons5.svg'
import icon3 from './icons/Icons6.svg'
import icon5Small from './icons/Group 10789.svg'

export const HowItWork2 = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.section}>
      <Container isWide={true}>
        {/* <AnimatedWrapper> */}
        <h3
          className="fancyUnderlineText sectionTitleNew text-center"
          style={{ marginBottom: '30px' }}
        >
          {/*t("home.howItWorks.title")*/}
          {/* <Trans i18nKey="home.titleWork"> */}
          Dlaczego warto zamówić catering odżywczy NutritionPro
          {/* </Trans> */}
        </h3>
        {/* <p style={{ textAlign: 'center', margin: "16px 0" }}>Dlaczego warto zamówić dietę pudełkową od NutritionPro</p> */}
        <div className={styles.sectionList}>
          <div className={styles.sectionListItem}>
            <div className={styles.sectionListImg}>
              <img src={icon1} className={styles.icon} alt="icon" />
            </div>
            <div className={styles.title}>
              Odżywcze zbilansowane menu dostosowane do Twoich celów
            </div>
            <div className={styles.sectionListNumber}></div>
          </div>

          <div className={styles.sectionListItem}>
            <div className={styles.sectionListImg}>
              <img src={icon2} className={styles.icon} alt="icon" />
            </div>
            <div className={styles.title}>
              Precyzyjnie zdefiniowane makroskładniki
            </div>
            <div className={styles.sectionListNumber}></div>
          </div>
          <div className={styles.sectionListItem}>
            <div className={styles.sectionListImg}>
              <img src={icon3} className={styles.icon} alt="icon" />
            </div>
            <div className={styles.title}>Smaczne i urozmaicone menu</div>
            <div className={styles.sectionListNumber}></div>
          </div>

          <div className={styles.sectionListItem}>
            <div className={styles.sectionListImg}>
              <img src={icon4} className={styles.icon} alt="icon" />
            </div>
            <div className={styles.title}>Tylko premium składniki</div>
            <div className={styles.sectionListNumber}></div>
          </div>

          <div className={styles.sectionListItem}>
            <div className={styles.sectionListImg}>
              <img
                src={icon5}
                className={styles.icon}
                id={styles.iconBig}
                alt="icon"
              />
              <img src={icon5Small} className={styles.iconSmall} alt="icon" />
            </div>
            <div className={styles.title}>300 dań w menu</div>
            <div className={styles.sectionListNumber}></div>
          </div>

          <div className={styles.sectionListItem}>
            <div className={styles.sectionListImg}>
              <img src={icon6} className={styles.icon} alt="icon" />
            </div>
            <div className={styles.title}>
              Średnia ocena naszych posiłków 4,7/5
            </div>
            <div className={styles.sectionListNumber}></div>
          </div>
        </div>
        {/* </AnimatedWrapper> */}
      </Container>
    </div>
  )
}
