import cx from "classnames"
import React, { useContext } from "react"
import { Trans, useTranslation } from "react-i18next"

import { AffiliateContext } from "../../contexts/AffiliateContext"
import { useSmoothScroll } from "../../hooks/useSmoothScroll"
import { AnimatedWrapper } from "../animated-wrapper"
import { Button2 } from "../button2"
import { Container } from "../container"
import { ContactFormNew } from "../forms/contact-form-new"
import styles from "./discount.module.css"
import leftImage from "./img_left.svg"
import rightImage from "./img_right.svg"

export const DiscountSection = () => {
  const { t } = useTranslation()
  const scroll = useSmoothScroll()

  const openOrderForm = selector => {
    scroll.animateScroll(document.getElementById(selector))
  }

  return (
    <section className={styles.discount}>
      <Container isWide={true}>
        {/* <AnimatedWrapper> */}
        <h3 className="fancyUnderlineText fancyUnderlineText--orange sectionTitleNew text-center">
          Razem <span>taniej </span>
        </h3>
        <div className={styles.discountInfoWrap}>
          <div className={cx(styles.image, styles.imageFirst)}>
            <img src={leftImage} alt="illustration" />
          </div>
          <div className={styles.middleColumn}>
            <div className={cx(styles.discountInfo, styles.topCol)}>
              <span className={cx(styles.title, "text-center")}>
                {t("home.discountDesc1Blueghost")}
              </span>
            </div>
            <div className={styles.twoCols}>
              <div
                className={styles.discountInfo}
                style={{ gridArea: "info2" }}
              >
                <svg
                  width="56"
                  height="26"
                  viewBox="0 0 56 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginBottom: "16px" }}
                >
                  <path
                    d="M43.2484 10.4001C45.4087 10.4001 47.1599 8.65398 47.1599 6.50008C47.1599 4.34618 45.4087 2.6001 43.2484 2.6001C41.0881 2.6001 39.3369 4.34618 39.3369 6.50008C39.3369 8.65398 41.0881 10.4001 43.2484 10.4001Z"
                    fill="#F3F3F1"
                  />
                  <path
                    d="M33.252 26.0002V24.4402C33.252 20.9926 36.0047 18.2002 39.4033 18.2002H47.0926C50.4912 18.2002 53.244 20.9926 53.244 24.4402V26.0002H33.252Z"
                    fill="#2FDF84"
                  />
                  <path
                    d="M45.421 18.2002H39.3365C35.9748 18.2002 33.252 20.9926 33.252 24.4402V26.0002H39.3365V24.4402C39.3365 20.9926 42.0593 18.2002 45.421 18.2002Z"
                    fill="#3DC383"
                  />
                  <path
                    d="M43.2486 12.9999C39.6533 12.9999 36.7295 10.0847 36.7295 6.49996C36.7295 2.91523 39.6533 0 43.2486 0C46.8439 0 49.7678 2.91523 49.7678 6.49996C49.7678 10.0847 46.8439 12.9999 43.2486 12.9999ZM43.2486 4.87497C42.3522 4.87497 41.6188 5.60622 41.6188 6.49996C41.6188 7.39371 42.3522 8.12495 43.2486 8.12495C44.145 8.12495 44.8784 7.39371 44.8784 6.49996C44.8784 5.60622 44.145 4.87497 43.2486 4.87497Z"
                    fill="#353643"
                  />
                  <path
                    d="M55.8519 26H51.1255V24.4C51.1255 22.1953 49.3578 20.4001 47.1868 20.4001H39.3096C37.1386 20.4001 35.3709 22.1953 35.3709 24.4V26H30.6445V24.4C30.6445 19.5457 34.5296 15.6001 39.3096 15.6001H47.1868C51.9668 15.6001 55.8519 19.5457 55.8519 24.4V26Z"
                    fill="#353643"
                  />
                  <path
                    d="M12.8255 10.4001C14.9858 10.4001 16.737 8.65398 16.737 6.50008C16.737 4.34618 14.9858 2.6001 12.8255 2.6001C10.6653 2.6001 8.91406 4.34618 8.91406 6.50008C8.91406 8.65398 10.6653 10.4001 12.8255 10.4001Z"
                    fill="#F3F3F1"
                  />
                  <path
                    d="M2.83008 26.0002V24.4402C2.83008 20.9926 5.58283 18.2002 8.98147 18.2002H16.6707C20.0694 18.2002 22.8221 20.9926 22.8221 24.4402V26.0002H2.83008Z"
                    fill="#2FDF84"
                  />
                  <path
                    d="M14.9991 18.2002H8.91461C5.55291 18.2002 2.83008 20.9926 2.83008 24.4402V26.0002H8.91461V24.4402C8.91461 20.9926 11.6374 18.2002 14.9991 18.2002Z"
                    fill="#3DC383"
                  />
                  <path
                    d="M12.8258 12.9999C9.23047 12.9999 6.30664 10.0847 6.30664 6.49996C6.30664 2.91523 9.23047 0 12.8258 0C16.4211 0 19.3449 2.91523 19.3449 6.49996C19.3449 10.0847 16.4211 12.9999 12.8258 12.9999ZM12.8258 4.87497C11.9294 4.87497 11.196 5.60622 11.196 6.49996C11.196 7.39371 11.9294 8.12495 12.8258 8.12495C13.7222 8.12495 14.4556 7.39371 14.4556 6.49996C14.4556 5.60622 13.7222 4.87497 12.8258 4.87497Z"
                    fill="#353643"
                  />
                  <path
                    d="M25.429 26H20.7026V24.4C20.7026 22.1953 18.935 20.4001 16.764 20.4001H8.8867C6.71572 20.4001 4.94806 22.1953 4.94806 24.4V26H0.22168V24.4C0.22168 19.5457 4.10676 15.6001 8.8867 15.6001H16.764C21.5439 15.6001 25.429 19.5457 25.429 24.4V26Z"
                    fill="#353643"
                  />
                </svg>
                <span className={styles.text}>
                  {t('home.discountOption1')}
                </span>
                <span
                  className={styles.discountAmount}
                  style={{ color: "#3DC383" }}
                >
                  10%
                </span>
              </div>
              <div
                className={styles.discountInfo}
                style={{ gridArea: "info3" }}
              >
                <svg
                  width="58"
                  height="28"
                  viewBox="0 0 58 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginBottom: "16px" }}
                >
                  <path
                    d="M48.0217 9.68648C49.6717 9.68648 51.0092 8.35285 51.0092 6.70774C51.0092 5.06263 49.6717 3.729 48.0217 3.729C46.3717 3.729 45.0342 5.06263 45.0342 6.70774C45.0342 8.35285 46.3717 9.68648 48.0217 9.68648Z"
                    fill="#F3F3F1"
                  />
                  <path
                    d="M9.78244 9.68648C11.4324 9.68648 12.77 8.35285 12.77 6.70774C12.77 5.06263 11.4324 3.729 9.78244 3.729C8.13248 3.729 6.79492 5.06263 6.79492 6.70774C6.79492 8.35285 8.13248 9.68648 9.78244 9.68648Z"
                    fill="#F3F3F1"
                  />
                  <path
                    d="M28.9019 12.5105C31.8719 12.5105 34.2795 10.11 34.2795 7.14883C34.2795 4.18763 31.8719 1.78711 28.9019 1.78711C25.932 1.78711 23.5244 4.18763 23.5244 7.14883C23.5244 10.11 25.932 12.5105 28.9019 12.5105Z"
                    fill="#FF8F62"
                  />
                  <path
                    d="M17.5498 27.9999V24.4254C17.5498 21.7922 19.6889 19.6594 22.3298 19.6594H35.4749C38.1159 19.6594 40.255 21.7922 40.255 24.4254V27.9999H17.5498Z"
                    fill="#FF8F62"
                  />
                  <path
                    d="M27.1099 19.6594H22.3298C19.6889 19.6594 17.5498 21.7922 17.5498 24.4254V27.9999H22.3298V24.4254C22.3298 21.7922 24.4689 19.6594 27.1099 19.6594Z"
                    fill="#FC743D"
                  />
                  <path
                    d="M28.3044 7.14883C28.3044 5.04703 29.5281 3.24788 31.292 2.36856C30.5702 2.00873 29.7647 1.78711 28.9019 1.78711C25.9312 1.78711 23.5244 4.18678 23.5244 7.14883C23.5244 10.1109 25.9312 12.5105 28.9019 12.5105C29.7647 12.5105 30.5702 12.2889 31.292 11.9291C29.5281 11.0498 28.3044 9.24824 28.3044 7.14883Z"
                    fill="#FC743D"
                  />
                  <path
                    d="M48.0222 11.8984C45.386 11.8984 43.2422 9.76087 43.2422 7.13243C43.2422 4.50399 45.386 2.36646 48.0222 2.36646C50.6584 2.36646 52.8023 4.50399 52.8023 7.13243C52.8023 9.76087 50.6584 11.8984 48.0222 11.8984ZM48.0222 5.94094C47.3626 5.94094 46.8272 6.47711 46.8272 7.13243C46.8272 7.78775 47.3626 8.32392 48.0222 8.32392C48.6819 8.32392 49.2172 7.78775 49.2172 7.13243C49.2172 6.47711 48.6819 5.94094 48.0222 5.94094Z"
                    fill="#353643"
                  />
                  <path
                    d="M57.5818 22.026H53.9967V20.8345C53.9967 19.1926 52.6559 17.8557 51.0092 17.8557H45.0342V14.2812H51.0092C54.6349 14.2812 57.5818 17.2195 57.5818 20.8345V22.026Z"
                    fill="#353643"
                  />
                  <path
                    d="M9.78199 11.8984C7.1458 11.8984 5.00195 9.76087 5.00195 7.13243C5.00195 4.50399 7.1458 2.36646 9.78199 2.36646C12.4182 2.36646 14.562 4.50399 14.562 7.13243C14.562 9.76087 12.4182 11.8984 9.78199 11.8984ZM9.78199 5.94094C9.12234 5.94094 8.58698 6.47711 8.58698 7.13243C8.58698 7.78775 9.12234 8.32392 9.78199 8.32392C10.4416 8.32392 10.977 7.78775 10.977 7.13243C10.977 6.47711 10.4416 5.94094 9.78199 5.94094Z"
                    fill="#353643"
                  />
                  <path
                    d="M3.8067 22.026H0.22168V20.8345C0.22168 17.2195 3.16857 14.2812 6.79422 14.2812H12.7693V17.8557H6.79422C5.1475 17.8557 3.8067 19.1926 3.8067 20.8345V22.026Z"
                    fill="#353643"
                  />
                  <path
                    d="M28.9015 14.2979C24.9484 14.2979 21.7314 11.0904 21.7314 7.14896C21.7314 3.2075 24.9484 0 28.9015 0C32.8546 0 36.0715 3.2075 36.0715 7.14896C36.0715 11.0904 32.8546 14.2979 28.9015 14.2979ZM28.9015 3.57448C26.9249 3.57448 25.3165 5.17823 25.3165 7.14896C25.3165 9.11969 26.9249 10.7234 28.9015 10.7234C30.878 10.7234 32.4865 9.11969 32.4865 7.14896C32.4865 5.17823 30.878 3.57448 28.9015 3.57448Z"
                    fill="#353643"
                  />
                  <path
                    d="M42.047 28H38.462V24.4255C38.462 22.7836 37.1212 21.4468 35.4745 21.4468H22.3294C20.6827 21.4468 19.3419 22.7836 19.3419 24.4255V28H15.7568V24.4255C15.7568 20.8105 18.7037 17.8723 22.3294 17.8723H35.4745C39.1001 17.8723 42.047 20.8105 42.047 24.4255V28Z"
                    fill="#353643"
                  />
                </svg>
                <span className={styles.text}>
                  Pierwsze zamówienie dla <b>3</b>  osób ze zniżką:
                </span>
                <span
                  className={styles.discountAmount}
                  style={{ color: "#FF8F62" }}
                >
                  15%
                </span>
              </div>
              <div
                className={styles.discountInfo}
                style={{ gridArea: "info5" }}
              >
                <svg
                  width="109"
                  height="27"
                  viewBox="0 0 109 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginBottom: "16px" }}
                >
                  <path
                    d="M54.6039 11.3996C56.7641 11.3996 58.5153 9.65349 58.5153 7.49959C58.5153 5.34569 56.7641 3.59961 54.6039 3.59961C52.4436 3.59961 50.6924 5.34569 50.6924 7.49959C50.6924 9.65349 52.4436 11.3996 54.6039 11.3996Z"
                    fill="#F3F3F1"
                  />
                  <path
                    d="M82.181 11.3996C84.3413 11.3996 86.0925 9.65349 86.0925 7.49959C86.0925 5.34569 84.3413 3.59961 82.181 3.59961C80.0208 3.59961 78.2695 5.34569 78.2695 7.49959C78.2695 9.65349 80.0208 11.3996 82.181 11.3996Z"
                    fill="#F3F3F1"
                  />
                  <path
                    d="M44.6074 26.9992V25.4392C44.6074 21.9916 47.3602 19.1992 50.7588 19.1992H58.4481C61.8467 19.1992 64.5994 21.9916 64.5994 25.4392V26.9992H44.6074Z"
                    fill="#FF543D"
                  />
                  <path
                    d="M72.1846 26.9992V25.4392C72.1846 21.9916 74.9373 19.1992 78.336 19.1992H86.0252C89.4238 19.1992 92.1766 21.9916 92.1766 25.4392V26.9992H72.1846Z"
                    fill="#2FDF84"
                  />
                  <path
                    d="M56.7765 19.1992H50.692C47.3302 19.1992 44.6074 21.9916 44.6074 25.4392V26.9992H50.692V25.4392C50.692 21.9916 53.4148 19.1992 56.7765 19.1992Z"
                    fill="#D83721"
                  />
                  <path
                    d="M84.3536 19.1992H78.2691C74.9074 19.1992 72.1846 21.9916 72.1846 25.4392V26.9992H78.2691V25.4392C78.2691 21.9916 80.9919 19.1992 84.3536 19.1992Z"
                    fill="#3DC383"
                  />
                  <path
                    d="M54.6041 13.9999C51.0088 13.9999 48.085 11.0847 48.085 7.49996C48.085 3.91523 51.0088 1 54.6041 1C58.1994 1 61.1232 3.91523 61.1232 7.49996C61.1232 11.0847 58.1994 13.9999 54.6041 13.9999ZM54.6041 5.87497C53.7077 5.87497 52.9743 6.60622 52.9743 7.49996C52.9743 8.39371 53.7077 9.12495 54.6041 9.12495C55.5005 9.12495 56.2339 8.39371 56.2339 7.49996C56.2339 6.60622 55.5005 5.87497 54.6041 5.87497Z"
                    fill="#353643"
                  />
                  <path
                    d="M82.1812 13.9999C78.5859 13.9999 75.6621 11.0847 75.6621 7.49996C75.6621 3.91523 78.5859 1 82.1812 1C85.7766 1 88.7004 3.91523 88.7004 7.49996C88.7004 11.0847 85.7766 13.9999 82.1812 13.9999ZM82.1812 5.87497C81.2849 5.87497 80.5515 6.60622 80.5515 7.49996C80.5515 8.39371 81.2849 9.12495 82.1812 9.12495C83.0776 9.12495 83.811 8.39371 83.811 7.49996C83.811 6.60622 83.0776 5.87497 82.1812 5.87497Z"
                    fill="#353643"
                  />
                  <path
                    d="M67.2073 26.9996H62.481V25.3996C62.481 23.1948 60.7133 21.3996 58.5423 21.3996H50.665C48.494 21.3996 46.7264 23.1948 46.7264 25.3996V26.9996H42V25.3996C42 20.5452 45.8851 16.5996 50.665 16.5996H58.5423C63.3223 16.5996 67.2073 20.5452 67.2073 25.3996V26.9996Z"
                    fill="#353643"
                  />
                  <path
                    d="M94.7845 26.9996H90.0581V25.3996C90.0581 23.1948 88.2904 21.3996 86.1195 21.3996H78.2422C76.0712 21.3996 74.3035 23.1948 74.3035 25.3996V26.9996H69.5771V25.3996C69.5771 20.5452 73.4622 16.5996 78.2422 16.5996H86.1195C90.8994 16.5996 94.7845 20.5452 94.7845 25.3996V26.9996Z"
                    fill="#353643"
                  />
                  <path
                    d="M27.6039 11.3996C29.7641 11.3996 31.5153 9.65349 31.5153 7.49959C31.5153 5.34569 29.7641 3.59961 27.6039 3.59961C25.4436 3.59961 23.6924 5.34569 23.6924 7.49959C23.6924 9.65349 25.4436 11.3996 27.6039 11.3996Z"
                    fill="#F3F3F1"
                  />
                  <path
                    d="M17.6084 26.9992V25.4392C17.6084 21.9916 20.3611 19.1992 23.7598 19.1992H31.449C34.8477 19.1992 37.6004 21.9916 37.6004 25.4392V26.9992H17.6084Z"
                    fill="#2FDF84"
                  />
                  <path
                    d="M29.7775 19.1992H23.6929C20.3312 19.1992 17.6084 21.9916 17.6084 25.4392V26.9992H23.6929V25.4392C23.6929 21.9916 26.4158 19.1992 29.7775 19.1992Z"
                    fill="#3DC383"
                  />
                  <path
                    d="M27.6041 13.9999C24.0088 13.9999 21.085 11.0847 21.085 7.49996C21.085 3.91523 24.0088 1 27.6041 1C31.1994 1 34.1232 3.91523 34.1232 7.49996C34.1232 11.0847 31.1994 13.9999 27.6041 13.9999ZM27.6041 5.87497C26.7077 5.87497 25.9743 6.60622 25.9743 7.49996C25.9743 8.39371 26.7077 9.12495 27.6041 9.12495C28.5005 9.12495 29.2339 8.39371 29.2339 7.49996C29.2339 6.60622 28.5005 5.87497 27.6041 5.87497Z"
                    fill="#353643"
                  />
                  <path
                    d="M40.2073 26.9996H35.481V25.3996C35.481 23.1948 33.7133 21.3996 31.5423 21.3996H23.665C21.494 21.3996 19.7264 23.1948 19.7264 25.3996V26.9996H15V25.3996C15 20.5452 18.8851 16.5996 23.665 16.5996H31.5423C36.3223 16.5996 40.2073 20.5452 40.2073 25.3996V26.9996Z"
                    fill="#353643"
                  />
                  <path
                    d="M9.56076 7.3188C11.2107 7.3188 12.5483 5.98518 12.5483 4.34006C12.5483 2.69495 11.2107 1.36133 9.56076 1.36133C7.9108 1.36133 6.57324 2.69495 6.57324 4.34006C6.57324 5.98518 7.9108 7.3188 9.56076 7.3188Z"
                    fill="#F3F3F1"
                  />
                  <path
                    d="M99.6668 9.6974C97.0931 9.6974 95 7.52276 95 4.8487C95 2.17464 97.0931 0 99.6668 0C102.241 0 104.334 2.17464 104.334 4.8487C104.334 7.52276 102.241 9.6974 99.6668 9.6974ZM99.6668 3.63653C99.0228 3.63653 98.5001 4.182 98.5001 4.8487C98.5001 5.5154 99.0228 6.06088 99.6668 6.06088C100.311 6.06088 100.834 5.5154 100.834 4.8487C100.834 4.182 100.311 3.63653 99.6668 3.63653Z"
                    fill="#353643"
                  />
                  <path
                    d="M109 20H105.5V18.7878C105.5 17.1174 104.191 15.7574 102.584 15.7574H96.75V12.1209H102.584C106.123 12.1209 109 15.1101 109 18.7878V20Z"
                    fill="#353643"
                  />
                  <path
                    d="M9.33358 9.6974C6.75996 9.6974 4.66699 7.52276 4.66699 4.8487C4.66699 2.17464 6.75996 0 9.33358 0C11.9072 0 14.0002 2.17464 14.0002 4.8487C14.0002 7.52276 11.9072 9.6974 9.33358 9.6974ZM9.33358 3.63653C8.68959 3.63653 8.16693 4.182 8.16693 4.8487C8.16693 5.5154 8.68959 6.06088 9.33358 6.06088C9.97757 6.06088 10.5002 5.5154 10.5002 4.8487C10.5002 4.182 9.97757 3.63653 9.33358 3.63653Z"
                    fill="#353643"
                  />
                  <path
                    d="M3.49994 20H0V18.7878C0 15.1101 2.87695 12.1209 6.41656 12.1209H12.2498V15.7574H6.41656C4.80892 15.7574 3.49994 17.1174 3.49994 18.7878V20Z"
                    fill="#353643"
                  />
                </svg>
                <span className={styles.text}>
                  Pierwsze zamówienie dla <b>5</b> osób ze zniżką:
                </span>
                <span
                  className={styles.discountAmount}
                  style={{ color: "#FF543D" }}
                >
                  20%
                </span>
              </div>
            </div>
            <div className={cx(styles.discountInfo, styles.formWrap)}>
              <ContactFormNew
                themeLight={true}
                horizontal={true}
                btnText="Proszę o kontakt"
              />
            </div>
          </div>
          <div className={styles.image}>
            <img src={rightImage} alt="illustration" />
          </div>
        </div>
        {/* </AnimatedWrapper> */}
      </Container>
    </section>
  )
}
