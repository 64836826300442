import cx from "classnames"
import {  Form, withFormik } from "formik"
import React, {useRef } from "react"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"

import {
  formatPhoneNumber,
  getCookie,
  getReferrer,
  getUTM,
} from "../../helpers"
import { Button2 } from "../button2"
import contactFormStyles from "./contact-form-new.module.css"
import styles from "./form.module.css"
import { PhoneErrorMessage } from "./forms-error-messages"
import { MaskedPhoneInput } from "./phone"

const rePhoneNumber = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

Yup.addMethod(Yup.string, "phone", function () {
  return this.test("phone", "Telefonní číslo musí obsahovat 12 znaků", value =>
    rePhoneNumber.test(value)
  )
})

export const ContactFormLayout = ({
  isSubmitting,
  values,
  errors,
  touched,
  setFieldValue,
  btnType = "secondary",
  btnText = "Mám zájem",
  form_name = "hromadna-objednavka",
}) => {
  const { t } = useTranslation()

  const phoneRef = useRef()

  return (
    <Form
      name={form_name}
      data-boom
      method="post"
      className={cx(contactFormStyles.contactForm)}
    >
      <div className={cx(styles.inputField, contactFormStyles.inputField)}>
        <MaskedPhoneInput
          innerRef={phoneRef}
          isWhite={true}
          isLg={true}
          name="phone"
          value={values.phone}
          error={errors.phone}
          onChange={value => {
            setFieldValue("phone", value)
          }}
          placeholder={t("forms.onlineOrderFormNumberLabel")}
        />
        {touched.phone && errors.phone && (
          <span className={cx(styles.error, contactFormStyles.error)}>
            {errors.phone}
          </span>
        )}
      </div>
      <Button2
        color="secondary"
        buttonType="submit"
        disabled={isSubmitting}
        className={contactFormStyles.contactFormButton}
      >
        {btnText || t("forms.hero2FormCTA")}
      </Button2>
    </Form>
  )
}

export const ContactFormNew = withFormik({
  mapPropsToValues: props => ({
    phone: "+48",
    referrer: "",
    ga: "",
    success: false,
    form_name: props.form_name,
  }),
  validationSchema: () =>
    Yup.object().shape({
      phone: Yup.string()
        .min(8, <PhoneErrorMessage />)
        .phone()
        .typeError("A number is required"),
    }),
  handleSubmit: async (
    { phone, form_name },
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    try {
      const UTMS = getUTM()
      let referrer = getReferrer()

      const isEn = document.location.pathname.includes("/en")
      const testovani = localStorage.getItem("website-version")

      const getFormName = () => {
        let formName
        if (form_name) {
          formName = isEn ? `${form_name}_en` : form_name
        } else {
          formName = isEn ? `hromadna-objednavka_en` : `hromadna-objednavka`
        }
        return formName
      }

      const data = {
        formName: getFormName(),
        language: isEn ? "en" : "pl",
        region: process.env.GATSBY_COUNTRY,
        branchId: process.env.GATSBY_BRANCH_ID,
        phone: formatPhoneNumber(phone),
        utm_source: UTMS.UTM_SOURCE,
        utm_medium: UTMS.UTM_MEDIUM,
        utm_campaign: UTMS.UTM_CAMPAIGN,
        utm_term: UTMS.UTM_TERM,
        utm_content: UTMS.UTM_CONTENT,
        referrer: referrer,
        roistat: getCookie("roistat_visit"),
        ga: getCookie("_ga"),
      }


      const req = await fetch(`${process.env.GATSBY_APP_API_URL}/poland/application`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
      if (req.ok) {
        await setSubmitting(false)
        await setFieldValue("success", true)
        window.location.href = isEn ? '/en/thank-you' : '/thank-you'


        setTimeout(() => {
          resetForm()
        }, 300)
      }
    } catch (err) {
      setSubmitting(false)
      setFieldValue("success", false)
    }
  },
})(ContactFormLayout)
