import cx from 'classnames'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { AnimatedWrapper } from '../animated-wrapper'
import { Container } from '../container'
import styles from './faq.module.css'
import AngleDownIcon from './icons/angle-down.svg'
import Img4 from './icons/icon1.svg'
import Img1 from './icons/icon2.svg'
import Img2 from './icons/icon3.svg'
import Img3 from './icons/icon4.svg'
import lessIcon from './icons/less-info.svg'
import moreIcon from './icons/more-info.svg'
import PlusIcon from './icons/plus.svg'

export const FAQ2 = () => {
  const [openedQuestion, setOpenedQuestion] = useState(0)
  const [openedSection, setOpenedSection] = useState(0)

  const { t } = useTranslation()

  const section0Texts = [

    // {
    //   question: "Proč potřebuji znát složení svého těla?",
    //   answer:
    //     "Měřením získáš přehled o tom, jaký máš poměr mezi svalovou a tukovou hmotou a jaké je množství útrobního tuku a vody v těle. Jedná se o důležité indikátory zdraví, a proto je potřeba na ně brát ohled už při sestavování jídelníčku.",
    // },
    // {
    //   question: "Jak probíhá měření?",
    //   answer:
    //     "Pro měření používáme přístroj Tanita. Náš zaškolený pracovník za tebou přijede na místo, které sám určíš, a změří tě. Měření trvá jen několik minut. Můžeš se ale také nechat změřit na jakékoli váze, která toto měření umožňuje, a výsledky nám poté zaslat.",
    // },
    // {
    //   question: "Jak často bych se měl nechat změřit?",
    //   answer:
    //     "Díky častějšímu měření jsme schopni přesněji sledovat tvoje výsledky a dynamicky upravovat jídelníček. Měření tedy doporučujeme realizovat pravidelně každé 2–3 týdny.",
    // },
    {
      question: 'Dlaczego nie macie w ofercie diet, tylko plany odżywcze?',
      answer: ` Słowo „dieta” kojarzy się z wyrzeczeniami, uczuciem głodu i brakiem energii. A to
                    nie ma żadnego związku z tym, co chcieliśmy dawać w naszych posiłkach. 
                    Dlatego postanowiliśmy zostać pierwszym w Polsce cateringiem odżywczym i 
                    zamiast diet oferować profesjonalne i pyszne plany odżywcze, które są 
                    skoncentrowane na odżywianiu Twojego organizmu i zapewnianiu mu pełni sił i 
                    wszystkich potrzebnych składników odżywczych.                     `,
    },
    {
      question: 'Nie wiem, który plan jest dla mnie odpowiedni. Czy ktoś pomoże mi wybrać?',
      answer:`Oczywiście, nasi eksperci z chęcią Ci pomogą. Skontaktuj się z nimi mailowo info@nutritionpro.pl lub telefonicznie +48 22 600 73 28. Biuro Obsługi Klienta jest otwarte w godzinach od 8:00 do 16:00.`,
    },
    {
      question: 'Czy mogę sprawdzić menu z wyprzedzeniem?',
      answer: `Oczywiście. Nasze menu jest zawsze dostępne z wyprzedzeniem na najbliższe dwa tygodnie i możesz je sprawdzić w panelu zamowień`,
    },
    // {
    //   question:
    //     "Nejím některé potraviny, dokážete mi upravit jídelníček dle mých požadavků?",
    //   answer:
    //     "Ano, můžeš si u nás z jídelníčku vyloučit až 3 druhy potravin a upravit ho tak podle svých preferencí. V tuto chvíli však nenabízíme možnost low-carb, low-fat, vegetarián, vegan či speciálních zdravotních diet.",
    // },
  ]

  const section1Texts = [
    {
      question: "W jaki sposób dowożone są posiłki?",
      answer: "Nasze posiłki dowożone są profesjonalnymi auto-chłodniami. Zachowanie ciągu chłodniczego na wszystkich etapach produkcji i transportu nie tylko gwarantuje bezpieczeństwo, ale także pozwala zachować najwyższą jakość posiłków.",
    },
    {
      question: "Mogę mieć dwa adresy dostaw? Jeden do domu, a drugi do pracy?",
      answer: "Jak najbardziej. Możesz korzystać z kilku adresów i ustalać dostawy na każdy dzień w dogodne dla Ciebie miejsce. Jeżeli nie ustawisz preferowanych adresów od razu przy składaniu zamówienia w panelu zamowień, to następnie również możesz dokonywać zmian do 48 godzin przed planowanym czasem dostawy, także telefonicznie.",
    },
    {
      question: "Czy mogę zamówić posiłki także na weekend?",
      answer: "Tak, nasi dostawcy realizują zamówienia również w soboty. Posiłki z planu wybranego na niedzielę dowożone są w soboty.",
    },
    {
      question: 'Czy mogę przekazać Wam klucz lub kod do domofonu?',
      answer: "Tak, istnieją obie opcje. W miarę możliwości zachęcamy do udostępniania kodów dostępu, aby usprawnić pracę naszych dostawców.",
    },
  ]

  const section2Texts = [
    {
      question: "Jakie informacje znajdę na etykietach dań?",
      answer: "Na etykiecie każdego dania znajdziesz najważniejsze informacje na jego temat: nazwę posiłku, nazwę planu, kaloryczność, występujące w daniu alergeny oraz datę przydatności do spożycia.",
    },
    {
      question: "Czy pojemnik, w którym przychodzą posiłki, mogę podgrzewać w mikrofali?",
      answer: "Tak, ale pamiętaj o zdjęciu folii ochronnej z góry opakowania.",
    },
    {
      question: "Do jakich pojemników na odpady należy wyrzucać pudełka po posiłkach?",
      answer: 'Folię wierzchnią należy wrzucić do pojemnika na odpady zmieszane. Natomiast tacki do żółtego pojemnika na tworzywa sztuczne, dzięki czemu łatwo zostaną poddane recyklingowi.',
    },

  ]

  const section3Texts = [
    {
      question: t('home.qa.tab4option1question'),
      answer: t('home.qa.tab4option1answer'),
    },
    {
      question: t('home.qa.tab4option2question'),
      answer: t('home.qa.tab4option2answer'),
    },
    {
      question: t('home.qa.tab4option3question'),
      answer: t('home.qa.tab4option3answer'),
    },
  ]

  let texts = []
  if (openedSection === 0) {
    texts = section0Texts
  } else if (openedSection === 1) {
    texts = section1Texts
  } else if (openedSection === 2) {
    texts = section2Texts
  } else if (openedSection === 3) {
    texts = section3Texts
  }

  return (
    <section className={styles.section} id="faq">
      <Container isWide={true} className={styles.container}>
        {/* <AnimatedWrapper> */}
        <h3 className="fancyUnderlineText sectionTitleNew text-center">
          {/* {t("home.qa.title")} */}
          <Trans i18nKey="home.qaTitleBlueghost">
            Najczęściej zadawane <span>pytania</span>
          </Trans>
        </h3>
        {/* </AnimatedWrapper> */}
        {/* <AnimatedWrapper> */}
        <div className={styles.tabsTop}>
          <button
            type="button"
            className={cx(styles.tab, {
              [styles.active]: openedSection === 0,
            })}
            onClick={() => setOpenedSection(0)}
          >
            <div className={styles.tabImgWrap}>
              <img src={Img3} className={styles.tabImg} alt="icon" />
            </div>

            <div className={styles.tabTitle}>Dopasowane menu</div>
          </button>

          <button
            type="button"
            className={cx(styles.tab, {
              [styles.active]: openedSection === 1,
            })}
            onClick={() => setOpenedSection(1)}
          >
            <div className={styles.tabImgWrap}>
              <img src={Img4} className={styles.tabImg} alt="icon" />
            </div>

            <div className={styles.tabTitle}>Dostawa i płatność</div>
          </button>

          <button
            type="button"
            className={cx(styles.tab, {
              [styles.active]: openedSection === 2,
            })}
            onClick={() => setOpenedSection(2)}
          >
            <div className={styles.tabImgWrap}>
              <img src={Img1} className={styles.tabImg} alt="icon" />
            </div>

            <div className={styles.tabTitle}>Inne pytania</div>
          </button>

        </div>

        <div className={styles.tabsContent}>
          {texts.map(({ question, answer }, index) => (
            <div
              className={styles.listItem}
              key={index}
              onClick={() => setOpenedQuestion(index)}
              opened={(openedQuestion === index).toString()}
            >
              <h4 className={styles.listTitle}>
                {question}{' '}
                {openedQuestion === index ? (
                  <img src={lessIcon} alt="icon" />
                ) : (
                  <img src={moreIcon} alt="icon" />
                )}
              </h4>
              <p className={styles.listText}>
                {openedQuestion === index ? answer : null}
              </p>
            </div>
          ))}
        </div>
        {/* </AnimatedWrapper> */}
      </Container>
    </section>
  )
}
