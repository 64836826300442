import cx from "classnames"
import React from "react"
import { Trans, useTranslation } from "react-i18next"
import Slider from "react-slick"

import { AnimatedWrapper } from "../animated-wrapper"
import { Button2 } from "../button2"
import { Container } from "../container"
import { LocalizedLink } from "../localized-link"
import { ReviewCard } from "../review/card"
import img1 from "./images/review1.svg"
import img2 from "./images/review2.svg"
import img3 from "./images/review3.svg"
import img4 from "./images/review4.svg"
import styles from "./reviews.module.css"
import nutrition from "../../../static/images/review-icons/nutrition.svg"
import google from "../../../static/images/review-icons/google.svg"
import fb from "../../../static/images/review-icons/facebook.svg"

// import img5 from './images/review5.jpg';
const ReviewsCarousel = () => {
  const settings = {
    arrows: true,
    infinite: true,
    // fade: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 1,
          dots: false,
        },
      },
    ],
  }
  return (
    <Slider
      {...settings}
      className={cx("reviews-carousel", styles.carouselList)}
    >
      {/* <div className={styles.carouselItem}>
        <img src={img4} className={styles.carouselItemImg} alt="img" />
      </div>
      <div className={styles.carouselItem}>
        <img src={img1} className={styles.carouselItemImg} alt="img" />
      </div>
      <div className={styles.carouselItem}>
        <img src={img2} className={styles.carouselItemImg} alt="img" />
      </div>
      <div className={styles.carouselItem}>
        <img src={img3} className={styles.carouselItemImg} alt="img" />
      </div> */}
      {/* <div className={styles.carouselItem}>
        <img src={img5} className={styles.carouselItemImg} alt="img" />
      </div> */}
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={google}
          name="Pavel Radzevich"
          date="20.04.2021"
          text="Zaproponowali  fajną powitalną promocje, zamówiłem na 21 dni i dostałem 10 dni gratisem. Fajna opcja aby się zapoznać z produkcją. Ze względu na dostawę i jedzenie wszystko ok. Dobrze ze opcja jest elastyczna i można przełożyć kilka dni na koniec ze względu wyjazdu . Polecam"
        />
      </div>
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={google}
          name="Tetiana Sidorska"
          date="20.04.2021"
          text="Chciałabym podzielić się swoją opinią na temat cateringu, który zamówiłam przez NutritionPro. Cały proces zamawiania i dostawy był bardzo sprawny i profesjonalny. Wszystkie potrawy były starannie zapakowane i prezentowały się bardzo apetycznie.
          Obsługa cateringowa była również godna pochwały. Dostawa była dokładna i terminowa. Personel był uprzejmy i pomocny, zapewniając wszelkie informacje, których potrzebowałam.
          Podsumowując, jestem bardzo zadowolona z zamówionego cateringu"
        />
      </div>
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={google}
          name="Patrycja Nowoświat"
          date="20.04.2021"
          text="Po przetestowaniu paru cateringów na terenie Krakowa nutritionpro mnie absolutnie oczarował, zarówno jakością składników z jakich przygotowywane są posiłki,  jak i smakiem i zbilansowaniem potraw. Jedzenie na najwyższym, restauracyjnym poziomie w przystępnej cenie. Co dla mnie ważne, w posiłkach nie brakuje odpowiedniej ilości świeżych, różnorodnych owoców i warzyw."
        />
      </div>
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={google}
          name="Alex Kirilov"
          date="20.04.2021"
          text="Wysokiej jakości, smaczny catering w Polsce. I to jeszcze z darmową dostawą! Dziękuję za moje smaczne śniadania, obiady i kolacje."
        />
      </div>
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={google}
          name="Mikhail Fedarenchyk"
          date="20.04.2021"
          text="Byłem w Polsce służbowo i nie wiedziałem, gdzie zamówić smaczne i zdrowe jedzenie. Zamówiłem w NutritionPro i byłam pod wrażeniem"
        />
      </div>
      {/* <div className={styles.carouselItem}>
        <ReviewCard
          iconType={google}
          name="Kuba Marlicki (Marlon)"
          date="20.04.2021"
          text="After almost a month I would like to recommend NutritionPro to everyone who want to lose weight. Everything from the very beginning was 100% professional. For now I can say that every meal I got was tasty and what is even more important their diet works. I'm losing weight. Of course apart from diet I also do some exercises and run, but without them It wouldn't be so easy. Special thanks to the delivery guy. He's always nice, always helpful and informing about any possible delays. Best box diet I have ever ordered and your competitors in Prague soon will have a problem If you will keep doing such a good job :)"
        />
      </div> */}
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={google}
          name="Andrew Podolski"
          date="20.04.2021"
          text="The meals are extremely delicious, I'm highly recommend these guys!"
        />
      </div>
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={google}
          name="Polina Bibikova"
          date="20.04.2021"
          text="Super catering. Pyszne jedzenie. Dobrze pomaga przy treningach, dając dużo energii"
        />
      </div>
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={google}
          name="Katerina Vaskina"
          date="20.04.2021"
          text="Delicious food and great service 👌🏻"
        />
      </div>
      <div className={styles.carouselItem}>
        <ReviewCard
          iconType={google}
          name="Maksim Zdanchuk"
          date="20.04.2021"
          text="Хочу подякувати компанії «NutritionPro» , за якісне обслуговування. По перше, це дуже смачно, по друге доставка та сам сервіс мене здивувала у гарному сенсі цього слова. Крута робота с замовленням, вчасно доставили. Думали що їжа буде просто корисною, а вона виявилася ще й дуже смачною."
        />
      </div>
    </Slider>
  )
}

export const Reviews2 = ({ id = "" }) => {
  const { t } = useTranslation()
  return (
    <section className={styles.section} id={id}>
      <Container isWide={true} className={styles.container}>
        {/* <AnimatedWrapper> */}
        <h3 className="fancyUnderlineText sectionTitleNew text-center">
          <Trans i18nKey="home.reviewsTitle">
            Co <span>mówią o nas</span> Co  nasi klienci?
          </Trans>
        </h3>
        <ReviewsCarousel />
        {/* </AnimatedWrapper> */}
      </Container>
    </section>
  )
}
